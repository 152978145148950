<template>
    <div v-if="Object.keys(currentScreen).length > 0">
        <div class="page">
            <!-- PAGE 1: PROFILE HEADER -->
            <applicant-profile
                :applicant="currentScreen.applicant"
                :created_at="currentScreen.created_at"
                :property="currentScreen.property"
                :products="currentScreen.products"
                :assigned-to="currentScreen.screen.assigned_to"
            >
            </applicant-profile>
            <!-- PAGE 1: RECOMMENDATION -->
            <screen-recommendation
                :screen="currentScreen.screen"
            ></screen-recommendation>
            <!-- PAGE 1: SUMMARY -->
            <order-summary :applicant="currentScreen.applicant"></order-summary>
        </div>
        <div class="page" style="margin-top: 80px">
            <screening-scorecard
                :scorecard="currentScreen.screen.scorecard"
            ></screening-scorecard>
            <applicant-credit></applicant-credit>
        </div>
        <div class="page" style="margin-top: 80px">
            <applicant-debt></applicant-debt>
            <household-incomes
                v-if="
                    currentScreen.applicant.incomes.length > 0 ||
                    currentScreen.applicant.employers.length > 0
                "
                :currentApplication="currentApplication"
            ></household-incomes>

            <rental-history
                v-if="currentScreen.applicant.addresses.length > 0"
                :addresses="currentScreen.applicant.addresses"
            ></rental-history>
            <crime-disclaimer></crime-disclaimer>
            <crime-summary
                :items="
                    $_.get(
                        currentScreen,
                        'screen.search.result.screening.ojin.tags'
                    )
                "
            ></crime-summary>
        </div>
        <div class="page" style="margin-top: 75px">
            <h5
                style="margin-top: 75px"
                class="primary font-weight-bold rounded px-2 py-2 white--text text-uppercase mb-2"
            >
                Crime Records
            </h5>
            <applicant-crime
                :items="
                    $_.get(
                        currentScreen,
                        'screen.search.result.screening.ojin.tags'
                    )
                "
            ></applicant-crime>
        </div>
        <div style="margin-top: 75px">
            <h5
                class="primary font-weight-bold rounded px-2 py-2 white--text text-uppercase"
            >
                Eviction Records
            </h5>
            <applicant-evictions
                :items="
                    $_.get(
                        currentScreen,
                        'screen.search.result.screening.ojin.tags'
                    )
                "
            ></applicant-evictions>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "order-print",
    components: {
        OrderSummary: () =>
            import("@components/screen/components/Print/OrderSummary"),
        ApplicantProfile: () =>
            import("@components/screen/components/Print/ApplicantProfile"),
        ApplicantCredit: () =>
            import("@components/screen/components/Print/ApplicantCredit"),
        ApplicantDebt: () =>
            import("@components/screen/components/Print/ApplicantDebt"),
        ScreenRecommendation: () =>
            import("@components/screen/components/Print/ScreenRecommendation"),
        ScreeningScorecard: () =>
            import("@components/screen/components/Print/ScreeningScorecard"),
        HouseholdIncomes: () =>
            import("@components/screen/components/Print/HouseholdIncomes"),
        RentalHistory: () =>
            import("@components/screen/components/Print/RentalHistory"),
        ApplicantCrime: () =>
            import("@components/screen/components/Print/ApplicantCrime"),
        ApplicantEvictions: () =>
            import("@components/screen/components/Print/ApplicantEvictions"),
        CrimeDisclaimer: () =>
            import("@components/screen/components/Print/CrimeDisclaimer"),
        CrimeSummary: () =>
            import("@components/screen/components/Print/CrimeSummary"),
    },
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters("Screens", ["currentScreen", "currentApplication"]),
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(to) {
                this.getCurrentScreen(to.params.id);
            },
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Screens", ["getCurrentScreen"]),
    },
};
</script>

<style scoped>
</style>