var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.currentScreen).length > 0
    ? _c("div", [
        _c(
          "div",
          { staticClass: "page" },
          [
            _c("applicant-profile", {
              attrs: {
                applicant: _vm.currentScreen.applicant,
                created_at: _vm.currentScreen.created_at,
                property: _vm.currentScreen.property,
                products: _vm.currentScreen.products,
                "assigned-to": _vm.currentScreen.screen.assigned_to
              }
            }),
            _c("screen-recommendation", {
              attrs: { screen: _vm.currentScreen.screen }
            }),
            _c("order-summary", {
              attrs: { applicant: _vm.currentScreen.applicant }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "page", staticStyle: { "margin-top": "80px" } },
          [
            _c("screening-scorecard", {
              attrs: { scorecard: _vm.currentScreen.screen.scorecard }
            }),
            _c("applicant-credit")
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "page", staticStyle: { "margin-top": "80px" } },
          [
            _c("applicant-debt"),
            _vm.currentScreen.applicant.incomes.length > 0 ||
            _vm.currentScreen.applicant.employers.length > 0
              ? _c("household-incomes", {
                  attrs: { currentApplication: _vm.currentApplication }
                })
              : _vm._e(),
            _vm.currentScreen.applicant.addresses.length > 0
              ? _c("rental-history", {
                  attrs: { addresses: _vm.currentScreen.applicant.addresses }
                })
              : _vm._e(),
            _c("crime-disclaimer"),
            _c("crime-summary", {
              attrs: {
                items: _vm.$_.get(
                  _vm.currentScreen,
                  "screen.search.result.screening.ojin.tags"
                )
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "page", staticStyle: { "margin-top": "75px" } },
          [
            _c(
              "h5",
              {
                staticClass:
                  "primary font-weight-bold rounded px-2 py-2 white--text text-uppercase mb-2",
                staticStyle: { "margin-top": "75px" }
              },
              [_vm._v(" Crime Records ")]
            ),
            _c("applicant-crime", {
              attrs: {
                items: _vm.$_.get(
                  _vm.currentScreen,
                  "screen.search.result.screening.ojin.tags"
                )
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-top": "75px" } },
          [
            _c(
              "h5",
              {
                staticClass:
                  "primary font-weight-bold rounded px-2 py-2 white--text text-uppercase"
              },
              [_vm._v(" Eviction Records ")]
            ),
            _c("applicant-evictions", {
              attrs: {
                items: _vm.$_.get(
                  _vm.currentScreen,
                  "screen.search.result.screening.ojin.tags"
                )
              }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }